import React, { useState, useEffect } from 'react'
import MultiSelect from "react-multi-select-component"
import DatetimeRangePicker from 'react-datetime-range-picker';
import moment from 'moment'
import utils from '../../services/utils'
import './ConversationsSearchCriteria.css'

const ConversationsSearchCriteria = (props) => {
    const { channels, queues, role, clients } = props
    const [topic, setTopic] = useState()
    const [subTopic, setSubTopic] = useState()
    const [customerNumber, setCustomerNumber] = useState()
    const [customerID, setCustomerID] = useState()
    const [customerName, setCustomerName] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectedChannels, setSelectedChannels] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [selectedClients, setSelectedClients] = useState([])
    const [startSearch, setStartSearch] = useState(false)

    // init from session storage
    useEffect(() => {
        try {
            const voiceChannel = channels.find(x => x.value.toLowerCase() === 'voice')
            if (voiceChannel) setSelectedChannels([voiceChannel])
            else setSelectedChannels(channels)

            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem('r2s-conversations-search-criteria'))
            if (!searchCriteriaTemp) return;

            if (searchCriteriaTemp.startDateTime) setStartDate(moment(searchCriteriaTemp.startDateTime))
            if (searchCriteriaTemp.endDateTime) setEndDate(moment(searchCriteriaTemp.endDateTime))
            if (searchCriteriaTemp.customerNumber) setCustomerNumber(searchCriteriaTemp.customerNumber)
            if (searchCriteriaTemp.customerID) setCustomerID(searchCriteriaTemp.customerID)
            if (searchCriteriaTemp.customerName) setCustomerName(searchCriteriaTemp.customerName)
            if (searchCriteriaTemp.selectedQueues) setSelectedQueues(searchCriteriaTemp.selectedQueues)
            if (searchCriteriaTemp.topic) setTopic(searchCriteriaTemp.topic)
            if (searchCriteriaTemp.subTopic) setSubTopic(searchCriteriaTemp.subTopic)
            if (searchCriteriaTemp.selectedClients) setSelectedClients(searchCriteriaTemp.selectedClients)
            if (searchCriteriaTemp.selectedChannels) setSelectedChannels(searchCriteriaTemp.selectedChannels)
        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        setStartSearch(true)
        if (!startDate || !endDate) return
        console.log('searching...')
        const searchCriteria = new Map()
        if (startDate) searchCriteria.set('startDateTime', startDate.toISOString())
        if (endDate) searchCriteria.set('endDateTime', endDate.toISOString())
        if (customerNumber) searchCriteria.set('customerNumber', customerNumber)
        if (customerID) searchCriteria.set('customerID', customerID)
        if (customerName) searchCriteria.set('customerName', customerName)
        if (selectedChannels) {
            searchCriteria.set('selectedChannels', selectedChannels)
            if (selectedChannels.length === channels.length) // all
                searchCriteria.set('channels', '*')
            else
                searchCriteria.set('channels', selectedChannels.map(x => x.value))
        }
        if (selectedQueues) {
            searchCriteria.set('selectedQueues', selectedQueues)
            if (selectedQueues.length === queues.length) // all
                searchCriteria.set('queues', '*')
            else
                searchCriteria.set('queues', selectedQueues.map(x => x.value))
        }
        if (selectedClients) {
            searchCriteria.set('selectedClients', selectedClients)
            if (selectedClients.length === clients.length) // all
                searchCriteria.set('clients', '*')
            else
                searchCriteria.set('clients', selectedClients.map(x => x.value))
        }
        if (topic) searchCriteria.set('topic', topic)
        if (subTopic) searchCriteria.set('subTopic', subTopic)
        props.search(searchCriteria)
        sessionStorage.setItem('r2s-conversations-search-criteria', JSON.stringify(utils.mapToObj(searchCriteria)))
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div class="search-wrap pl-2 pb-2">
            <div class='row justify-content-start'>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Channels</span>
                        </div>
                        <MultiSelect
                            options={channels}
                            value={selectedChannels}
                            onChange={e => { console.log('setSelectedChannels:', e); setSelectedChannels(e) }}
                            labelledBy="Select"
                            disableSearch={true}
                        />
                    </div>
                </div>
                <div class='col col-12 col-xl-3' >
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Queues</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={queues}
                            value={selectedQueues}
                            onChange={e => { setSelectedQueues(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div class='col col-12 col-xl-auto'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Interval</span>
                        </div>

                        <DatetimeRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(e) => {
                                setStartDate(e.start)
                                setEndDate(e.end)
                            }}
                            viewMode='months'
                            pickerClassName={getDatePickerClassName()}
                            isValidEndDate={(currentDate) => { return currentDate > moment() ? false : true }}
                            isValidStartDate={(currentDate) => { return currentDate > moment() ? false : true }}
                        />
                    </div>
                </div>
            </div>
            <div class='row justify-content-start'>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Customer Name</span>
                        </div>
                        <input class='form-control conversations-custom-control' type='text' value={customerName}
                            onChange={e => setCustomerName(e.target.value)} />
                    </div>
                </div>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Customer ID</span>
                        </div>
                        <input class='form-control conversations-custom-control' type='text' value={customerID}
                            onChange={e => setCustomerID(e.target.value)} />
                    </div>
                </div>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Customer Number</span>
                        </div>
                        <input class='form-control conversations-custom-control' type='text' value={customerNumber}
                            onChange={e => setCustomerNumber(e.target.value)} />
                    </div>
                </div>
            </div>
            <div class='row justify-content-start'>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Topic</span>
                        </div>
                        <input class='form-control conversations-custom-control' type='text' value={topic}
                            onChange={e => setTopic(e.target.value)} />
                    </div>
                </div>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Sub Topic</span>
                        </div>
                        <input class='form-control conversations-custom-control' type='text' value={subTopic}
                            onChange={e => setSubTopic(e.target.value)} />
                    </div>
                </div>
                {role && (role === "admin" || role === "internal_user") && (
                    <div class='col col-12 col-xl-3'>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Clients</span>
                            </div>

                            <MultiSelect  className="multi-select clients-multi-select"
                                options={clients}
                                value={selectedClients}
                                onChange={e => { setSelectedClients(e) }}
                                labelledBy="Select"
                            />

                        </div>
                    </div>
                )}
            </div>
            <br />
            <div class='row justify-content-start'>
                <div class='col-xs-2 pl-3 pr-2'>
                    <button type="button" class="btn btn-primary button-custom" onClick={search}>Search</button>
                </div>
                <div class='col-xs-2' >
                    <button type="button" class="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )


}

export default ConversationsSearchCriteria