import React, { useState, useEffect, Fragment } from 'react'
import MultiSelect from "react-multi-select-component"
import Select from "react-select"
import {Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label} from 'reactstrap'
import './UserDetails.css'
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'



const UserDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)

    const [dropDownsDisable,setDropDownsDisable] = useState(false)
    const [alertHeader, setAlertHeader] = useState("Error")

    const [emailAddressEntered, setEmailAddressEntered] = useState('')
    const [firstNameEntered, setFirstNameEntered] = useState('')
    const [lastNameEntered, setLastNameEntered] = useState('')
    const [roleSelected, setRoleSelected] = useState({})
    const [channelsSelected, setChannelsSelected] = useState([])
    const [clientIdsSelected, setClientIdsSelected] = useState([])
    const [divisionsSelected, setDivisionsSelected] = useState([])
    const [queuesSelected, setQueuesSelected] = useState([])
    const [stateSelected, setStateSelected] = useState(false)


    const [rolesList, setRolesList] = useState([])
    const [channelsList, setChannelsList] = useState([])
    const [clientIdsList, setClientIdsList] = useState([])
    const [divisionsList, setDivisionsList] = useState([])
    const [queuesList, setQueuesList] = useState([])
    const [displayQueueList, setDisplayQueueList] = useState()


    useEffect(() => {

        // const removeAll = (arrayToModify) => {
        //     const deleteIndex = arrayToModify.findIndex(obj => obj.value === "*")
        //     if ((deleteIndex > -1) && (arrayToModify.length > 1))
        //         arrayToModify.splice(deleteIndex, 1)
        //     return arrayToModify
        // }

        const replaceAll = (arrayToReplace) => {
            if ((arrayToReplace.length === 1) && (arrayToReplace[0].value === "*")) {
                return true
            }
            return false

        }
        

        const getUserDetails = async () => {
            try {
            const getUserMeResponse = await backendAPI.getUsersMe(sessionStorage.getItem('r2s-token'))
            setRolesList(getUserMeResponse.user.roles ? getUserMeResponse.user.roles.filter((x => x.value !== '*')) : [])
            setChannelsList(getUserMeResponse.user.channels ? getUserMeResponse.user.channels.filter((x => x.value !== '*')) : [])
            setClientIdsList(getUserMeResponse.user.clients ? getUserMeResponse.user.clients.filter((x => x.value !== '*')) : [])
            setDivisionsList(getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [])
            setQueuesList(getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [])
            sessionStorage.setItem('r2s-getUserMeResponse', JSON.stringify(getUserMeResponse))
            setRoleSelected(roleSelected.length>0 ? roleSelected : null)

            if (props.userId) {

                const getUserbyIDResponse = await backendAPI.getUserByID(sessionStorage.getItem('r2s-token'), props.userId)
                setEmailAddressEntered(getUserbyIDResponse.user.email)
                setFirstNameEntered(getUserbyIDResponse.user.firstName)
                setLastNameEntered(getUserbyIDResponse.user.lastName)
                setRoleSelected(getUserbyIDResponse.user.role)
                setChannelsSelected(getUserbyIDResponse.user.channels)
                if (replaceAll(getUserbyIDResponse.user.channels))
                    setChannelsSelected(getUserMeResponse.user.channels.filter((x => x.value !== '*')))
                setClientIdsSelected(getUserbyIDResponse.user.clients)
                if (replaceAll(getUserbyIDResponse.user.clients))
                    setClientIdsSelected(getUserMeResponse.user.clients.filter((x => x.value !== '*')))
                setDivisionsSelected(getUserbyIDResponse.user.divisions)
                if (replaceAll(getUserbyIDResponse.user.divisions))
                    setDivisionsSelected(getUserMeResponse.user.divisions.filter((x => x.value !== '*')))
                setQueuesSelected(getUserbyIDResponse.user.queues)
                if (replaceAll(getUserbyIDResponse.user.queues))
                    setQueuesSelected(getUserMeResponse.user.queues.filter((x => x.value !== '*')))
                if (getUserbyIDResponse.user.disabled)
                    setStateSelected(getUserbyIDResponse.user.disabled)
                sessionStorage.setItem('r2s-getUserbyIDResponse', JSON.stringify(getUserbyIDResponse))
                if(getUserbyIDResponse.user.role.value === "admin")
                setDropDownsDisable(true)
                else
                setDropDownsDisable(false)
                
            }

        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

        }

        getUserDetails()

    }, [])
    
    const filterQueue =  (DivisionsInp,queueToFilter)=> {
        let selectedDivison = DivisionsInp.map( e => e.value)
        let queuesFilter = []
        let filteredQueue = []
        selectedDivison.forEach (element => {
            queuesFilter = queueToFilter.filter(x => x.divisionId === element)
            filteredQueue = filteredQueue.concat(queuesFilter)
        })
        return (filteredQueue)

    }


    return (
  
            <div className = "form-elements">
            <WaitingModal
            isOpen={isWaitingModalOpen}
            header='Loading User Details'
            />
             <AlertModal
            isOpen={!!error}
            header={alertHeader}
            toggle={() => { setError(undefined) }}
            body={error}
            />
            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email Address <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                
                <Input
                    placeholder="Users's Email Address"
                    defaultValue={emailAddressEntered}
                    onChange={e => setEmailAddressEntered(e.target.value)}
                    disabled = {props.userId? true : false}
                />
            </InputGroup>

            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Firstname <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Input 
                    placeholder="User's Firstname"
                    defaultValue={firstNameEntered}
                    onChange={e => setFirstNameEntered(e.target.value)}
                />
            </InputGroup>
            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Lastname <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder="User's Lastname"
                    defaultValue={lastNameEntered}
                    onChange={e => setLastNameEntered(e.target.value)}
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Role <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                 <Select className = "single-select"
                       placeholder = "Select..."
                        options={rolesList}
                        value={roleSelected}
                        onChange={e => { setRoleSelected(e) 
                            if(e.value === "admin"){
                                setDropDownsDisable(true)
                                setChannelsSelected(channelsList)
                                setClientIdsSelected(clientIdsList)
                                setDivisionsSelected(divisionsList)
                                setQueuesSelected(queuesList)
                            }
                            else{
                                setDropDownsDisable(false)
                                setChannelsSelected([])
                                setClientIdsSelected([])
                                setDivisionsSelected([])
                                setQueuesSelected([])
                            }

                             }}
                    />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Channels{<span className = "required-astrik"> * </span>}</InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={channelsList}
                    value={channelsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => { setChannelsSelected(e) }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Client IDs<span className = "required-astrik">{(roleSelected && (roleSelected.value !== "client_user" && roleSelected.value !== "internal_user")) ? '' : '*'}</span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={clientIdsList}
                    value={clientIdsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => { setClientIdsSelected(e) }}
                    labelledBy="Select"
                   //disabled={roleSelected.value != "client_user" }
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Divisions<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={divisionsList}
                    value={divisionsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setDivisionsSelected(e)
                                    const selectedDivison = e;
                                    setDisplayQueueList(filterQueue(selectedDivison,queuesList))
                                    setQueuesSelected(filterQueue(selectedDivison,queuesSelected))
                                    }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Queues<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={displayQueueList ? displayQueueList : 
                            (filterQueue(divisionsSelected,queuesList).length>0 ? filterQueue(divisionsSelected,queuesList) : queuesList)}
                    value={queuesSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => setQueuesSelected(e)}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <Input addon type="checkbox" onChange={e => setStateSelected(e.target.checked)} checked={stateSelected} />
            <Label>&nbsp; Disable </Label>
            <br />
            <br />
                                    
            <Button color="primary" onClick={() => {
            
                if (!emailAddressEntered || !firstNameEntered || !lastNameEntered 
                    || (roleSelected && roleSelected.length === 0) || (channelsSelected && channelsSelected.length === 0) 
                    || ((clientIdsSelected && clientIdsSelected.length === 0) && (roleSelected.value === "client_user" || 
                    roleSelected.value === "internal_user")) || (divisionsSelected && divisionsSelected.length === 0) || (queuesSelected && queuesSelected.length === 0)) {
                    setError(`Please enter all the required fields before submitting`)
                    setAlertHeader("Validation Error")
                    return
                }

                props.submit({
                emailAddressEntered,
                firstNameEntered,
                lastNameEntered,
                roleSelected: rolesList.length === roleSelected.length ? [''] : roleSelected,
                channelsSelected: channelsList.length === channelsSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : channelsSelected,
                clientIdsSelected: clientIdsList.length === clientIdsSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : clientIdsSelected,
                stateSelected: stateSelected,
                divisionsSelected: divisionsList.length === divisionsSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : divisionsSelected,
                queuesSelected: queuesList.length === queuesSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : queuesSelected,
                UserId: props.userId ? props.userId : ''
            })}}>{props.okButtonText ? props.okButtonText : 'OK'} </Button>
            &nbsp;&nbsp;
            <Button color="secondary" disabled={props.loading? true : false} onClick={() => props.cancel()}>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</Button>
            <br />
            <br />
                    </div>
            
    )      
}

export default UserDetails