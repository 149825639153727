import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer" >
            <span>version 0.8</span>
        </footer>
    )
}

export default Footer