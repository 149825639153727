import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Input, Button } from 'reactstrap'
import sanitizeHtml from 'sanitize-html'
import { Spinner } from 'reactstrap'

import backendAPI from '../../services/backend'
import AlertModal from '../Modals/alertModal/AlertModal'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import './PrivacyDisclaimer.css'

const PrivacyDisclaimer = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)
    const history = useHistory()
    const location = useLocation()

    const [agreeCheck, setAgreeCheck] = useState(false)
    const [consentResponse, setConsentResponse] = useState([])
    const [loginResponse, setLoginResponse] = useState([])
    const [sendingLogin, setSendingLogin] = useState(false)
    const [tempToken, setTempToken] = useState(location.state ? location.state.token : '')

    const fetchDisclaimerContent = async () => {
        try {
            const disclaimerResponse = await backendAPI.getDisclaimerContent(tempToken);
            setConsentResponse(disclaimerResponse.consentSettings);
            sessionStorage.setItem('r2s-disclaimerResponse', JSON.stringify(disclaimerResponse.consentSettings))
            //console.log(disclaimerResponse)
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

    }

    const notifyUserLogin = async () => {
        try {
            setSendingLogin(true)
            console.log("Calling notify user login")
            const notifyLoginResponse = await backendAPI.postUserLogin(tempToken);
            sessionStorage.setItem('r2s-userLoginResponse', JSON.stringify(notifyLoginResponse))
            //  console.log(notifyLoginResponse.token)
            setLoginResponse(notifyLoginResponse)
            sessionStorage.setItem('r2s-token', notifyLoginResponse.token)
            history.push("/conversations")

        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setSendingLogin(false)
        }

    }

    useEffect(() => {
        //console.log('location.state.token',location.state.token)
        fetchDisclaimerContent()
    }, [])


    return (
        <div id="component-wrap">
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header='Loading Privacy Disclaimer'
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <div id="center-container">
                    <div className="center-content">
                        <h4 className="content">{consentResponse.header ? consentResponse.header : "Recording As A Service"}</h4>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(consentResponse.body ? consentResponse.body : "") }} />
                        <br />
                        {(isWaitingModalOpen || error) ? null : (<p className="content"><Input addon type="checkbox" onChange={e => { setAgreeCheck(e.target.checked); console.log(agreeCheck) }} />&nbsp; {consentResponse.footer ? consentResponse.footer : "I agree"}</p>)}
                        <div className="continue-button">
                            <br />
                            {(isWaitingModalOpen || error) ? null : (<Button color="primary" disabled={!agreeCheck}
                                onClick={() => { notifyUserLogin() }
                                }> Continue {sendingLogin ? <Spinner size="sm" /> : null}</Button>)}
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default PrivacyDisclaimer