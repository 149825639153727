import utils from './utils'
import config from '../config'

function checkStatus(response) {
    if (!response.ok) {
        throw new Error(`HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
}

// TODO: Clarify All URI resources with backend dev

export const getRecording = async (id) => {
    const uri = `${config.endpoints.backend}/recordings/${id}`
    // console.log('fetching uri:', uri)
    return fetch(uri).then(response => checkStatus(response) && response.arrayBuffer())
        .then(buffer => {
            // console.log('getRecording.buffer:', buffer)
            return { id, buffer }
        })
        .catch(error => {
            const err = { id, error }
            throw err
        })
}

export const getConversationsSettings = async (token) => {
    const uri = `${config.endpoints.backend}/settings/conversations`
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const updateConversationsSettings = async (token, conversationColumns) => {
    const uri = `${config.endpoints.backend}/settings/conversations`
    return utils.fetchWrapper(uri, {
        method: 'PUT',
        headers: {
            token
        },
        body: JSON.stringify({ conversationColumns })
    })
}

export const getConversations = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (key === 'selectedClients' || key === 'selectedChannels' || key === 'selectedQueues') continue;
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item)
                        queryString += `${key}=${item}&`
            }
            else
                queryString += `${key}=${value}&`

        }
    }
    const uri = `${config.endpoints.backend}/search${queryString}`
    console.log('fetching uri:', uri)
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const getConversation = async (token, conversationId) => {
    const uri = `${config.endpoints.backend}/conversation?conversation_id=${conversationId}`
    console.log('fetching uri:', uri)
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}


export const createUser = async (token, user) => {
    const uri = `${config.endpoints.backend}/users`
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token
        },
        body: JSON.stringify({
            "channels": user.channelsSelected,
            "clients": user.clientIdsSelected,
            "disabled": user.stateSelected,
            "divisions": user.divisionsSelected,
            "email": user.emailAddressEntered,
            "firstName": user.firstNameEntered,
            "lastName": user.lastNameEntered,
            "queues": user.queuesSelected,
            "role": user.roleSelected
        })
    })
}

export const updateUser = async (token, id, user) => {
    const uri = `${config.endpoints.backend}/users/${id}`
    return utils.fetchWrapper(uri, {
        method: 'PUT',
        headers: {
            token
        },
        body: JSON.stringify({
            "channels": user.channelsSelected,
            "clients": user.clientIdsSelected,
            "disabled": user.stateSelected,
            "divisions": user.divisionsSelected,
            "email": user.emailAddressEntered,
            "firstName": user.firstNameEntered,
            "lastName": user.lastNameEntered,
            "queues": user.queuesSelected,
            "role": user.roleSelected,
            "id": user.UserId
        })
    })
}

export const getUsersMe = async (token) => {
    const uri = `${config.endpoints.backend}/users/me`
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const getUserListAll = async (token) => {
    const uri = `${config.endpoints.backend}/users`
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const getUserByID = async (token, id) => {
    const uri = `${config.endpoints.backend}/users/${id}`
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}


export const getUsersListSearch = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item)
                        queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/users${queryString}`
    console.log('fetching uri:', uri)
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}
export const getAuditAccessReport = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item) queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/audit/access${queryString}`
    console.log('fetching uri:', uri)
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const getAuditTermsReport = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item) queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/audit/terms${queryString}`
    console.log('fetching uri:', uri)
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}

export const getDisclaimerContent = async (token) => {
    const uri = `${config.endpoints.backend}/settings/consent`
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token
        }
    })
}
export const getConversationMetadata = async (token, conversationId, recordings) => {
    const uri = `${config.endpoints.backend}/conversations/${conversationId}/metadata`
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token
        },
        body: JSON.stringify({ recordings })
    })
}


export const postUserLogin = async (token) => {
    const uri = `${config.endpoints.backend}/users/login`
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token
        }
    })
}

export default {
    getUsersMe,
    getConversation,
    getConversations,
    getConversationsSettings,
    updateConversationsSettings,
    createUser,
    getUserListAll,
    getUserByID,
    updateUser,
    getUsersListSearch,
    getAuditAccessReport,
    getAuditTermsReport,
    getDisclaimerContent,
    postUserLogin,
    getConversationMetadata
}