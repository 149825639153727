// private config
const development = {
    endpoints: {
        backend: 'https://api-alight-r2s-dev.genesyscsp.net',
    }
}

const production = {
    endpoints: {
        backend: 'https://api-alight-r2s.genesyscsp.com',
    }
}

const qa = {
    endpoints: {
        backend: 'https://api-alight-r2s-qa.genesyscsp.net',
    }
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'qa': config = qa; break;
    default: break;
}

export default config