
import React, { useEffect, Fragment, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import backend from '../services/backend'
import WaitingModal from '../components/Modals/waitingModal/WaitingModal'
import AlertModal from '../components/Modals/alertModal/AlertModal'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [error, setError] = useState()
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)
  const [role, setRole] = useState()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const usersMe = await backend.getUsersMe(sessionStorage.getItem('r2s-token'))
        setRole(usersMe.user.role.value)
      } catch (error) {
        setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
        console.log(error)
      } finally {
        setIsWaitingModalOpen(false)
      }
    }
    fetchUser()
  }, [])

  return (
    <Fragment>
      <WaitingModal
        isOpen={isWaitingModalOpen}
        header='Loading user profile'
      />
      <AlertModal
        isOpen={!!error}
        header='Error'
        toggle={() => { setError(undefined) }}
        body={error}
      />
      {role &&
        <Route {...rest} render={(props) => (
          sessionStorage.getItem('r2s-token')
            ? <Component role={role} {...props} />
            : <Redirect to='/unauthorized' />
        )} />
      }
    </Fragment>
  )
}

export default PrivateRoute