import React, { useState } from 'react'
import MultiSelect from "react-multi-select-component"
import DatetimeRangePicker from 'react-datetime-range-picker';
import moment from 'moment'
import './CommonSearchTermsSearchCriteria.css'

const CommonSearchTermsSearchCriteria = (props) => {
    const { clients, users } = props
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectedClients, setSelectedClients] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [startSearch, setStartSearch] = useState(false)

    const search = async () => {
        setStartSearch(true)
        if (!startDate || !endDate) return
        console.log('searching...')
        const searchCriteria = new Map()
        if (startDate) searchCriteria.set('startDate', startDate.toISOString())
        if (endDate) searchCriteria.set('endDate', endDate.toISOString())
        if (selectedClients) {
            if (selectedClients.length === clients.length) // all
                searchCriteria.set('clients', '*')
            else
                searchCriteria.set('clients', selectedClients.map(x => x.value))
        }
        if (selectedUsers) {
            if (selectedUsers.length === users.length) // all
                searchCriteria.set('users', '*')
            else
                searchCriteria.set('users', selectedUsers.map(x => x.value))
        }
        props.search(searchCriteria)
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div class="search-wrap pl-2">
            <div class='row'>
                <div class="col col-12 col-xl-3">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Users</span>
                        </div>
                        <MultiSelect
                            options={users}
                            value={selectedUsers}
                            onChange={e => { setSelectedUsers(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div class='col col-12 col-xl-3'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Clients</span>
                        </div>

                        <MultiSelect
                            options={clients}
                            value={selectedClients}
                            onChange={e => { setSelectedClients(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col col-12 col-xl-auto'>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Interval</span>
                        </div>
                        <DatetimeRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(e) => {
                                setStartDate(e.start)
                                setEndDate(e.end)
                            }}
                            viewMode='months'
                            pickerClassName={getDatePickerClassName()}
                            isValidEndDate={(currentDate) => { return currentDate > moment() ? false : true }}
                            isValidStartDate={(currentDate) => { return currentDate > moment() ? false : true }}
                        />
                    </div>
                </div>
            </div>
            <br />
            <div class='row'>
                <div class='col-xs-2 pl-3 pr-2'>
                    <button type="button" class="btn btn-primary button-custom" onClick={search}>Search</button>
                </div>
                <div class='col-xs-2' >
                    <button type="button" class="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default CommonSearchTermsSearchCriteria